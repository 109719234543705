<template>
  <div v-if="isLoggedIn == true" class="brands container">
      <div v-if="loader">
        <ProgressSpinner id="loader" />
      </div>
       <div v-if="brands.length"  class="vendors" style="height: calc(100vh - 100px)">
        <DataTable  :value="brands" 
          filterDisplay="menu" 
          :scrollable="true" scrollHeight="flex"
          :paginator="true" 
          :rows="50"
          v-model:filters="filters1" 
          :loading="loader"
          :globalFilterFields="['vendor_name']"
        >
        <template #header>
                    <div class="left" style="text-align: left">
                       <h3>Store Vendor List</h3>
                    </div>
                    <div class="right" style="text-align: right">
                        <span class="p-input-icon-left">
                            <i class="pi pi-search"></i>
                            <input v-model="filters1['global'].value" placeholder="Keyword Search" />
                        </span>
                    </div>
                </template>
          <template #loading>
                    Loading variants data. Please wait.
                </template>
          <Column field="vendor_name" header="Vendor Name" >
            <template #body="{data}">

                    <a v-bind:href="`/vendors/vendor-variants/${ data.vendor_name.replace(/\s+/g, '-').toLowerCase() }`" class="vendor_text">
                      <h3>{{ data.vendor_name?.replace(/&amp;/g, '&')}}</h3>
                      <Button icon="pi pi-external-link" label="Check" />
                  </a>
              </template>
          </Column>
        </DataTable>
      </div>
      <div v-bind:class="(loader)?'loading':'loaded'"></div>
    </div>
    <div v-else class="error">
      Please login to check the page <a href="/">Login</a>
    </div>
    
</template>

<script>
  import { mapActions,mapGetters } from "vuex";
  import DataTable from 'primevue/datatable';
  import Column from 'primevue/column';
  import {FilterMatchMode,FilterOperator} from 'primevue/api';
  import ProgressSpinner from 'primevue/progressspinner';
  import Button from 'primevue/button';
  export default {
    name: 'productsBrands',
    components: {
    ProgressSpinner,
    DataTable,
    Column,
    Button

   },
      data() {
        return {
          loader:true,
          brands:{},
          filters1: null,
        }
     },
     created() {
      this.initFilters1();
     },
      methods: {
           ...mapActions(["getBrands"]),
           ...mapGetters(["isAuthenticated"]), 
           initFilters1() {
            this.filters1 = {
                    'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
                    'vendor_name': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]}
                }
            }
      },
      mounted:async function () {
        if(this.isLoggedIn){
            this.brands =  await this.getBrands(this.userToken);
            console.log("this.brands",this.brands)            ;
            this.loader = false;
        }
      },
      computed: {
        isLoggedIn : function(){
            return this.$store.getters.isAuthenticated
        },
        userToken : function(){
          return this.$store.getters.getUserToken
        },
      },
  
  
  }
  </script>
  